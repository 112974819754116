import Head from 'next/head';
import React from 'react';

import { PromoCta } from '@goodfynd/react-web.lib.content';

import { setPageTitle } from '../utils/base';

import { Container, LayoutWithHeader } from '@components/index';
import strings from '@config/strings';

function Home(): JSX.Element {
  return (
    <>
      <Head>
        <title>{setPageTitle('Home')}</title>
        <meta name="description" content="home page" />
      </Head>

      <Container
        css={{
          '&.MuiContainer-root': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
            flexGrow: 1,
          },
        }}
      >
        <PromoCta
          image_position="right"
          button_href="/dashboard"
          button_text="Get Started"
          content="Streamline your operations, boost your sales, and delight your
          customers."
          image="https://res.cloudinary.com/foodease/image/upload/v1639950106/assets/maintenance-truck.png"
          title={strings.titles.homeTitle}
        />
      </Container>
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <LayoutWithHeader>{page}</LayoutWithHeader>;
};

export default Home;
